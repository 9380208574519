<template>
  <div class="page-holder">
  <!--   <Head v-if="solution !== null">
      <Title>{{solution.solutionPageTitle === null ? $rt(solution.solution) : $rt(solution.solutionPageTitle) }}</Title>
      <Meta name="description" :content="solution.solutionMetaDesc === null ? $rt(solution.solution) : $rt(solution.solutionMetaDesc)" />
    </Head> -->
    <BaseComponents v-for="(item, index) in page" :item="item" :isLast="getIsLast(page, index)" />
  </div>
</template>

<script>
import gsap from 'gsap';
import { usePillarStore } from '~/store/PillarStore';
import BaseComponents from '~/components/BaseComponents.vue';
import { usePerformanceStore } from '~/store/PerformanceStore';
import { useSolutionStore } from '~/store/SolutionStore';
import solResources from '../../../json/languages/en/en.solutions.resources.json';
import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseComponents,
  },
  setup() {
    const pillarStore = usePillarStore();
    const performanceStore = usePerformanceStore();
    const solutionStore = useSolutionStore();
    return {
      pillarStore,
      performanceStore,
      solutionStore,
    };
  },
  data() {
    return {
      selectedNav: 0,
      content: null,
      translatedSolutions: null,
    };
  },

  mounted() {
   
  },
  methods: {
    getIsLast(page, index) {
      return page.length - 1 === index;
    },
    handleSelection(index) {
      if (this.selectedNav != index) {
        const id = '#nav' + index;
        const prevId = '#nav' + this.selectedNav;
        gsap.to(id, {
          textDecoration: 'underline',
        });
        gsap.to(prevId, {
          textDecoration: 'none',
        });
        this.selectedNav = index;
      }
    },
    toUpper(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join('');
    },
  },
  computed: {
    terms() {
      return this.$tm('extraTerms');
    },
    getContent() {
      return this.content;
    },
    isListCheck() {
      const route = useRoute();
      if (route.params.id === 'boom_irrigation') {
        return true;
      } else {
        return false;
      }
    },
    getRoute() {
      const route = useRoute();
      return route.params.id;
    },
    page() {
      var productData = this.$tm(this.getRoute);
      const resourceIndex = productData.findIndex(x => this.$rt(x.type) === 'resources');
      const productIndex = productData.findIndex(x => this.$rt(x.type) === 'products');
      productData[resourceIndex].details = this.resources;
      productData[productIndex].items = this.relatedProducts;
      console.log(productData[productIndex]);
      console.log(productData, ' product data');

      return productData;
    },
    solution() {
      var solData = this.$tm('solutions');
      var sol = this.getRoute;
      var currSolution = solData.find(x => this.$rt(x.solutionURL) === sol);
      return currSolution;
    },
    productFamilies() {
      return this.$tm('productFamilies');
    },
    resources() {
      var curLocale = useI18n().locale.value;
      var tmp = this.$tm('solutionResources');
      var resources = [];
      //need to get english resources first.
      var enResources = solResources.solutionResources.filter(
        (element) => parseInt(element.cspID_fk) === parseInt(this.solution.solID_fk)
      )
      if (curLocale !== 'en') {
        resources = tmp.filter(
          (element) => (parseInt(element.cspID_fk) === parseInt(this.solution.solID_fk)) && (element.filename !== null || element.videoURL !== null)
        );
      }
      if (curLocale === 'en')
        resources = enResources;
      else {
        enResources.forEach((en) => {
          var found = resources.find((re) => re.libID_fk === en.libID_fk);
          if (!found) {
            resources.push(en);
          }
        })
      }

      return resources;
    },
    relatedProducts() {
      const solutionStore = useSolutionStore();
      const allProducts = this.$tm('products');
      if (this.solution) {
        const products = solutionStore.getSolutionProducts(this.solution.solID_fk, allProducts, this.productFamilies);
        return products;
      } else {
        return [];
      }
    }
  },
};
</script>
<style scoped>
hr {
  width: 100%;
}

.line {
  width: 100%;
  padding: 0.5px 0;
  background-color: #d3cdc2;
}

.mechanized-irrigation-container {
  display: grid;
  grid-template-columns: 1200px;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

@media only screen and (max-width: 1200px) {
  .mechanized-irrigation-container {
    display: flex;
    flex-direction: column;
  }

  .line {
    width: 95%;
  }
}

.page-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
