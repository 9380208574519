<template>
  <div v-if="getItems" class="solutions-detail-container" :id="getItems.title + getItems.index">
    <div class="items-holder">
      <div class="sd-items-section">
        <h1 v-if="getItems.title" class="sd-item-title" v-html="$rt(getItems.title).replace(/[™®©]/g, '<sup>$&</sup>')"></h1>
        <div v-for="(element, index) in getItems.section" :key="index">
          <div v-if="element.subheader" class="sd-subheader-item">{{ $rt(element.subheader) }}</div>
          <p v-for="(text, index) in element.paragraphs" :key="index">
            {{ $rt(text) }}
          </p>
        </div>
      </div>
      <div class="sd-items-list" v-if="getItems.list">

        <h1 class="sd-item-title" v-html="$rt(getItems.title).replace(/[™®©]/g, '<sup>$&</sup>')"></h1>

        <div class="sd-items-para-points" v-for="(element, index) in getItems.list">
          <p v-if="element.paragraph" class="sd-item-paragraph">{{ $rt(element.paragraph) }}</p>

          <ul class="sd-ul" v-if="element.points">
            <li class="sd-li" v-for="(point, index) in element.points" v-html="$rt(point).replace(/[™®©]/g, '<sup>$&</sup>')"></li>
          </ul>
        </div>
      </div>
      <div class="sd-items-list" v-if="getItems.subList">
        <h1>{{ $rt(getItems.title) }}</h1>
        <div v-for="subItem in getItems.subList.subValues">
          <ol v-if="$rt(subItem.subType) === 'numbered'" v-for="element in subItem.values">
            <li>{{ $rt(element.subHeader) }}</li>
            <ul>
              <li v-for="point in element.points">{{ $rt(point) }}</li>
            </ul>
          </ol>
          <div v-if="$rt(subItem.subType) === 'none'" v-for="element in subItem.values">
            <p>{{ $rt(element.subHeader) }}</p>
            <p v-for="item in element.paragraphs">{{ $rt(item) }}</p>
          </div>
        </div>
      </div>
      <div class="sd-items-link" v-if="getItems.link">
        <p>
          Link to
          <nuxt-link :to="getItems.link"> {{ $rt(getItems.linkName) }}</nuxt-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      solutionsData: null,
    };
  },
  mounted() {
    this.solutionsData = this.$props.items;
  },
  props: ['items'],
  methods: {
    getItemsLength(items) {
      if (items === null) {
        return 0;
      } else {
        return items.length;
      }
    }
  },
  computed: {
    getItems() {
      return this.solutionsData;
    },
  },
};
</script>

<style scoped>
.solutions-detail-container {
        max-width: 800px;
          width: 90%;
}

.sd-item-title {
  margin-bottom: 20px;
}

.solution-detail-alt {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
  margin-top: 20px;
}

.items-holder {
      padding: 15px 0 20px 0;
        max-width: 800px;
        width: 90%;
}


.items-resourceUri {
  width: 100%;
}

.sd-item-img {
  width: 100%;
}

.sd-li {
  font-size: 15px;
}

.sd-ul {
  margin-top: 10px;
}

.sd-item-paragraph {
  margin-top: 15px;
}

.sd-subheader-item {
  font-weight: 700;
  padding: 1rem 0;
}

@media only screen and (max-width: 750px) {
  .solutions-detail-container {
    flex-direction: column-reverse !important;
  }

  .solutions-detail-image-box {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .solutions-detail-image-box {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>