<template>
  <div v-if="getItems" :style="detailTextContainer">
    <div v-if="getItems.isVideo">
      <div>
        <iframe width="100%" height="400px" loop :src="$rt(getItems.videoUri)" frameborder="0"
          allow="autoplay; loop; fullscreen; picture-in-picture; clipboard-write" allowfullscreen>
        </iframe>
      </div>
    </div>
    <div class="solutions-detail-image-box" v-else>
      <div class="items-resourceUri" v-if="getItems.resourceUri.length === 1"
        v-for="(item, num) in getItems.resourceUri">
        <img class="sm-item-img" :src="$rt(item)" />
        <p class="solution-detail-alt" v-if="getItems.alt">{{ $rt(getItems.alt[num]) }}</p>
      </div>
      <div style="display: grid; grid-template-columns: auto auto; gap: 10px" v-else>
        <div v-for="(item, num) in getItems.resourceUri">
          <img class="sm-item-img" :src="$rt(item)" />
          <p class="solution-detail-alt" v-if="getItems.alt">{{ getItems.alt[num] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      solutionData: null
    }
  },
  mounted () {
    this.solutionData = this.$props.items
  },
  props: ['items'],
  computed: {
    getItems () {
      return this.solutionData;
    },
    detailTextContainer() {
      if (this.solutionData.isRight) {
        return {
          float: 'right',
          width: '50%',
          padding: '0 0 1rem 1rem'
          // 'flex-direction': 'row',
        };
      } else {
        return {
          float: 'left',
          width: '50%',
          padding: '0 1rem 1rem 0'
        };
      }
    },
  }
}
</script>
<style scoped>
.solutions-detail-image-box {
  flex: 1;
  width: 100%;
}
.solution-detail-alt {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
  margin-top: 20px;
}
.sm-item-img {
  width: 100%;
}
.items-resourceUri {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .solutions-detail-image-box {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 500px) {

  .solutions-detail-image-box {
    padding-left: 0;
    padding-right: 0;
  }

}
</style>