<template>
  <div>
    <div class="testimonials-container" :id="$rt(items.index)">
      <h1 class="testimonials-title" id="testimonials-title">{{ $rt(Props.items.title) }}</h1>
      <p class="testimonials-summary" id="testimonials-summary">{{ $rt(Props.items.paragraphs[0]) }}</p>
      <div class="testimonials-content">
        <div class="testimonials-details" v-for="(item, index) in Props.items.details" :key="index">
          <iframe class="testimonials-video" :src="$rt(item.videoUri)" :height="400" :width="300" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
          <p class="testimonials-text" id="testimonials-paragraph">{{ $rt(item.summary) }}</p>
          <div class="testimonials-company-box">
            <h5 class="testimonials-person-name">{{ $rt(item.name) }}</h5>
            <p class="testimonials-company-name">{{ $rt(item.company) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['items'],
  computed: {
    Props () {
      return this.$props;
    },
  },
};
</script>
<style scoped>
.testimonials-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  padding: 1rem 0;
  background-color: #efece0;
}
.testimonials-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding: 15px;
}
.testimonials-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  background-color: #FFFFFF;
}
.testimonials-video {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .testimonials-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .testimonials-summary {
    margin: 10px !important;
    width: 90% !important;

  }
}
@media only screen and (max-width: 800px) {
  .testimonials-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .testimonials-summary {
    padding: 0 30px;
    width: 100% !important;
    margin: 0px !important;
  }
}

.testimonials-title {
  font-size: 35px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 30px;
}

.testimonials-summary {
  font-size: 15px;
  margin: 30px;
  width: 80%;
}

.testimonials-text {
  font-size: 16px;
  margin-top: 15px;

}

.testimonials-person-name {
  font-size: 13px;
  margin-top:25px;
  margin-bottom: 15px;
}

.testimonials-company-name {
  font-size: 16px;
  margin-bottom: 10px;
}
</style>