<template>
  <div>
    <div class="blog-detail-container" :id="$rt(Props.items.index)">
      <h1 class="blog-title" id="blog-title">{{ $rt(Props.items.blogTitle) }}</h1>
      <div class="blog-detail-layout">
        <div class="blog-detail-column" v-for="(post, index) in Props.items.details" :key="index">
          <img class="blog-detail-img" :src="$rt(post.resourceUri)" />
          <h3 class="blog-post-title">{{ $rt(post.postTitle) }}</h3>
          <div class="blog-post-info">
            <p v-if="post.author" class="blog-author">By: {{ $rt(post.author) }}</p>
            <p v-if="post.date" class="blog-date">{{ $rt(post.date) }}</p>
          </div>
          <p class="blog-text" id="blog-post-summary">{{ $rt(post.summary) }}</p>
          <BaseButton :buttonStyle="blogStyle" :label="$t('readMore')" :url="$rt(post.path)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from './BaseButton.vue';

export default {
  components: { BaseButton },
  props: ['items'],
  computed:{
    Props (){
      return this.$props;
    }
  },
  data () {
    return {
      blogStyle: {
        color: '#0089D0',
        hover: '#212121'
      }
    };
  }
};
</script>
<style scoped>
p {
  margin: 5px 0;
  font-size: 15px;
}
h3 {
  margin: 5px 0;
}
.blog-detail-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EFECE0;
  padding: 15px;

}
.blog-detail-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-content: center;
}
.blog-detail-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 20px;
}
.blog-detail-img{
  width: 100%;
}
.blog-post-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  line-height: 1rem;
  color: #0089D0;
  font-weight: 900;
  font-size: 13px;
}
.blog-post-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
}
@media only screen and (max-width: 1200px) {
  .blog-detail-layout {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media only screen and (max-width: 800px) {
  .blog-detail-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blog-post-info {
    display: flex;
    flex-direction: column;
  }
}

.blog-title {
  font-weight: 900;
  font-size: 30px;
  margin: 20px 20px 30px;
}

.blog-author {
  font-size: 13px;
  font-weight: 900;
}

.blog-date {
  font-size: 13px;
  font-weight: 900;
}

.blog-text {
  font-size: 15px;
  margin-bottom: 20px;
}
</style>