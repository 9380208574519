<template>
  <div v-if="getOptions && getPlaceHolder" tabindex="0" @focusout="this.handleCloseMenu" class="base-input">
    <label class="input-label">{{ getLabel }}</label>
    <div @click="this.handleMenu" class="base-dropdown-selection-box">
      <div class="dd-text-holder">
        <p v-if="getSelectedOption != null"><b>{{ getSelectedOption.name }}</b></p>
        <p v-else class="placeholder-text">{{ getPlaceHolder }}</p>
      </div>
      <div class="dd-icon-holder">
        <img v-if="getIsMenu === false" class="dropdown-icon" src="data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23222222%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E">
        <img v-else class="dropdown-icon" id="dropdown-icon-open" src="data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23222222%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E">      
      </div>
    </div>
    <div v-show="getIsMenu" class="base-dropdown-options-box">
      <div class="options-holder">
        <div v-for="element, index in getOptions" :key="index" @click="this.handleOption($event, element)" class="base-dropdown-option">
          <p :id="getFieldValue">{{ element.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    this.optionsData = this.$props.options;
    this.placeholderText = this.$props.placeholder;
    this.labelText = this.$props.label;
  }, 
  data () {
    return {
      optionsData: null,
      selectedOption: null,
      isMenu: false,
      placeholderText: null,
      labelText: null
    };
  },
  props: {
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    fieldValue: {
      type: String
    },
    method: {
      type: Function
    }
  },
  computed: {
    getSelectedOption () {
      return this.selectedOption;
    },
    getOptions () {
      return this.optionsData;
    },
    getIsMenu () {
      return this.isMenu;
    },
    getPlaceHolder () {
      return this.placeholderText;
    },
    getLabel () {
      return this.labelText;
    },
    getFieldValue () {
      return this.$props.fieldValue;
    }
  },
  methods: {
    handleMenu () {
      this.isMenu = this.isMenu ? false : true;
    },
    handleCloseMenu () {
      this.isMenu = false;
    },
    handleOption (event, option) {
      this.selectedOption = option;
      this.$props.method(event, this.selectedOption);
      this.isMenu = false;
    }
  }
};
</script>
<style scoped>
.base-dropdown-options-box {
  position: relative;

   z-index: 99;
    width: 100%;
    height: 100%;

}
.base-dropdown-option {
  cursor: pointer;
  padding-left: .6em;
}
.base-dropdown-option:hover {
  color: #FFFFFF;
  background-color: #3B3B3B;
}
.base-dropdown-selection-box {
  border: 1px solid black;
  background-color: #F7F6F1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.3;
  padding: .6em 1.8em .5em .8em;
  box-sizing: border-box;
  margin: 0;
  border-radius: 2px;
}
.dropdown-icon {
  width: 13px;
  height: 13px;
  user-select: none;
}

#dropdown-icon-open {
  rotate: 180deg;
}

.placeholder-text {
  font-weight: bold;

}

.options-holder {
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  border-radius: 2px;
  height: 150px;
  width: inherit;
}

.dd-icon-holder {
  margin-left: 20px;
}
</style>