<template>
  <div class="webinars-container">
    <div :id="$rt(Props.items.index)">
      <h1 class="webinars-title" id="webinars-title">{{ $rt(Props.items.title) }}</h1>
      <div class="webinars-content">
        <div class="webinars-box"v-for="(item, index) in Props.items.details" :key="index">
          <div class="webinars-text-box">
            <iframe class="webinars-video" :width="350" :height="350" :src="$rt(item.videoUri)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p class="webinars-text" id="webinars-text">{{ $rt(item.summary) }}</p>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="webinars-button-box">
      <BaseButton :buttonStyle="webinarStyle" :label="$rt(Props.items.buttonText)" :url="$rt(Props.items.buttonPath)" />
    </div>
  </div>
</template>
<script>
import gsap from 'gsap';
import BaseButton from './BaseButton.vue';
export default {
  components: { BaseButton },
  data () {
    return {
      resource: [],
      webinarStyle: {
        color: '#F48B41',
        hover: '#3B3B3B'
      }
    };
  },
  mounted () {
    this.resource = this.$props.items;
  },
  props: [
    'items'
  ],
  computed:{
    Props (){
      return this.$props;
    }
  },
  methods: {
    handleLink () {
     window.location.href = this.$props.url;
    },
    handleHover () {
      let el = this.$refs.baseBtn;
      gsap.to(el, {
        backgroundColor: this.webinarStyle.hover,
        duration: .4
      });
    },
    handleLeave () {
      let el = this.$refs.baseBtn;
      gsap.to(el, {
        backgroundColor: this.webinarStyle.color,
        duration: .4
      });
    }
  }
};
</script>
<style scoped>
.webinars-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.webinars-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  overflow: auto;
}
.webinars-box{
  width: 350px;
}
.webinars-text-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 350px;
}

.webinars-text {
  font-size: 14px;
  font-weight: bold;
  padding: 30px 10px;
  background-color: #dcdcdc;
}

.webinars-button-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 115px;
  margin-top: 2rem;
  background-color: #3B3B3B;
}
#webinars-title {
  display: flex;
  justify-content: center;
}

.webinars-video {
  width: 100%;
}

.webinars-title {
  font-size: 32px;
  font-weight: 900 !important;
  margin-top: 20px;
  margin-bottom: 50px;
}


@media only screen and (max-width: 1200px) {
  .webinars-content {
    display: flex;
  }
}
@media only screen and (max-width: 800px) {
  .webinars-content {
    display: flex;
    justify-content: center;
  }
}



</style>