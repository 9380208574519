<template>
  <div v-if="getItems" class="base-story-container">
    <div class="base-story-detail-box">
      <div>
        <div class="base-story-icon-box">
          <img :src="$rt(getItems.typeIcon)">
          <h3>{{ $rt(getItems.typeTitle) }}</h3>
        </div>
        <h2>{{ $rt(getItems.title) }}</h2>
        <h3>{{ $rt(getItems.name) }}</h3>
      </div>
      <div>
        <iframe :src="$rt(getItems.videoUri)"  class="base-story-video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
    </div>
    <div class="base-story-suggest-box">
      <div class="base-story-suggest" v-for="element, index in getItems.suggested" :key="index">
        <iframe :src="$rt(element.videoUri)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
        </iframe>
        <h3>{{ $rt(element.title) }}</h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      storyData: null
    };
  },
  mounted () {
    this.storyData = this.$props.items;
  },
  props: [
    'items'
  ],
  computed: {
    getItems () {
      return this.storyData;
    }
  }
};
</script>
<style scoped>
.base-story-detail-box {
  display: flex;
  flex-direction: row;
  background-color: #EFECE0;
  padding: 30px 30px 30px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-story-icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.base-story-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.base-story-video {
  width: 600px;
  height: 300px;
}
.base-story-suggest-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.base-story-suggest {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 1rem;
  border: 1px solid #D3CDC2;
}
.base-story-suggest:hover {
  background-color: #222222;
  color: #FFFFFF;
}
</style>