<template>
  <div class="table-content-container">
    <div class="table-content-image-box">
      <img class="table-content-image" :src="$rt(Props.items.resourceUri)" />
    </div>
    <div class="table-content-details">
      <h1 class="table-content-title">{{ $rt(Props.items.title) }}</h1>
      <ul class="table-content-list">
        <li v-for="(link, index) in Props.items.items" :key="index" class="table-content-list-li" id="content-link"
          @click="this.handleLink($rt(link.index))">{{ $rt(link.title) }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'items'
  ],
  methods: {

    handleLink (link) {
      this.scrollToElement(link);
    },

    scrollToElement (link) {
      const element = document.getElementById(link);
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
      }
    },
  },
  computed: {
    Props () { return this.$props; }
  }
};
</script>
<style scoped>
.table-content-list {
  width: 70%;
}

.table-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
}

.table-content-title {
  font-size: 35px;
  padding-bottom: 20px;
  font-weight: 700;
  margin: 0;
}

#content-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.table-content-details {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  justify-content: center;
  align-items: center;
}

.table-content-image-box {
  flex:2;
}

.table-content-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table-content-list-li {
  font-size: 15px;
}

@media only screen and (max-width: 1200px) {
  .table-content-container {
    display: flex;
    flex-direction: column !important;
  }

  .table-content-image-box {
    display: flex;
    justify-content: center;
  }

  .table-content-details {
    padding: 2rem 0 0 0;
  }

  .table-content-list {
    width: 80%;
  }

  .table-content-image {
    padding-left: 30px;
    padding-right: 30px;
  }
}


@media only screen and (max-width: 1200px) {

}

</style>