import { defineStore } from 'pinia';
import SolutionProducts from '~/json/products.solutions.json';
import { usePerformanceStore } from './PerformanceStore';

export const useSolutionStore = defineStore('solution', {
  state () {
    return {
      solutionResourcesData: null,
      currentSolutionResources: null,
      solutionProductsData: null,
      currentSolutionProducts: null,
      productFamilies: null,
    };
  },
  actions: {
    getSolutionResources (solID_fk) {
      this.currentSolutionResources = this.solutionResourcesData.filter(
        (element) => element.cspID_fk === solID_fk
      );
      this.currentSolutionResources.forEach((item) => {
        if (item.videoURL != null && item.videoURL != 'NULL') {
          item.filePath = item.videoURL;
          item.type = 'Video';
        } else {
          const fileReg = item.filename.match(/\.([0-9a-z]+)(?:[#]|$)/i);
          item.filePath =
            '/library/' + item.filename;
          item.type = fileReg[1].toUpperCase();
        }
      });
      if (this.currentSolutionResources.length === 0) {
        this.currentSolutionResources = null;
      }
    },
    getSolutionProducts (solID_fk, allProducts, productFamilies) {
      const productKeys = SolutionProducts.productSolutions.filter(
        (element) => element.solID_fk === solID_fk
      );
      var filterProducts = productKeys.map((element) => {
        let solutionProducts = allProducts.find(({pID}) => pID === element.pID_fk)
        let prodFam = null;
        if (solutionProducts) {
          prodFam = productFamilies.find(({ pfID_fk }) => pfID_fk === solutionProducts.pfID_fk);
          solutionProducts.productFam = prodFam;    
        }
        return solutionProducts
      })
      // when it can't find a product
      filterProducts = filterProducts.filter(n => n != undefined);
      
      const sortProducts = filterProducts.sort(
        (a, b) => a.productSortOrder - b.productSortOrder
      );
      // this.currentSolutionProducts = sortProducts;
      return sortProducts;
    },
    checkImageExists (url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
        img.src = url;
      });
    },
  },
});
