<template>
  <div class="base-detail-qa-container">

    <div class="detail-qa-title" :id="$rt(items.index)">{{ $rt(items.title)}}</div>

    <div class="detail-list-item" v-for="detail, index in rersourcesData.details">
      <div @click="handleSelected(index)" class="description-box">
        <h2 class="detail-description-list" id="detail-description-list">{{ $rt(detail.description) }}</h2>
        <div v-if="detail.isSelected === false" class="triangle-up"></div>
        <div v-if="detail.isSelected" class="triangle-down"></div>
      </div>
      <div class="list-summary" v-if="detail.isSelected">
        <p class="list-item-text" v-if="detail.paragraph">{{ $rt(detail.paragraph) }}</p>
        <div v-if="detail.list">
          <p class="list-item-text" v-if="detail.list.subheader != null">{{ $rt(detail.list.subheader) }}</p>
          <ul style="list-style-type: none;">
            <li class="list-item-text" v-for="point in detail.list.points">{{ $rt(point) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data () {
    return {
      resources: []
    };
  },
  mounted () {
    this.resources = this.$props.items;
    for (let detail in this.resources.details) {
      this.resources.details[detail].isSelected = false;
    }
  },
  
  computed: {
    rersourcesData () {
      return this.resources;
    }
  },
  props: [
    'items'
  ],
  methods: {
    handleSelected (index) {
      this.resources.details[index].isSelected = this.resources.details[index].isSelected ? false : true;
    }
  }
};
</script>
<style scoped>

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #F48B41;
}

#detail-description-list {
  color: #3B3B3B;
  font-size: 25px;
  font-weight: 900;
}

.base-detail-qa-container {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.description-box {
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #E7E7E7;
  font-size: 15px;
  font-weight: 900px;
  color: #3B3B3B;
  padding: 25px;
  margin: 0;
}

.triangle-down {
  color: orange;
  rotate: 180deg;
}

.list-summary {
  background-color: #f7f6f1;
  border: 1px solid #E7E7E7;
  padding: 5px 15px;
  margin-bottom: 5px;
  width: 100%;
}

#detail-list-title {
  text-align: center;
}

.detail-list-item {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-qa-title {
  font-weight: 900;
  font-size: 35px;
  padding-bottom: 30px;
}

.list-item-text {
  font-size: 15px;
  padding: 15px;
}

.detail-description-list {
  padding-right: 40px;

}

@media only screen and (max-width: 600px) {
  .base-detail-qa-container {
    padding: 0 20px;
  }
  .detail-description-list {
    font-size: 18px !important;
  }
}
</style>