<template>
  <div v-if="getItems" class="solutions-detail-container">
    <div v-if="getItems.section" :id="getIndex">
      <SolutionsMedia :items="getItems" />
      <div :style="compWidth">
        <div :style="compInnerWidth" class="sd-content">
          <span>
            <h1 v-if="getItems.title" class="sd-item-title" v-html="$rt(getItems.title).replace(/[™®©]/g, '<sup>$&</sup>')"></h1>
            <div v-for="(element, index) in getItems.section" :key="index">
              <div v-if="element.subheader" class="sd-item-subheader">{{ $rt(element.subheader) }}</div>
              <p v-for="(text, index) in element.paragraphs" :key="index">
                {{ this.$rt(text) }}
              </p>
              <!-- <div v-for="(text, index) in element.paragraphs" :key="index" v-html="$rt(text)">
              </div> -->
            </div>
          </span>
        </div>
        <div style="padding-top: 1rem; display: flex; justify-content: center;" v-if="checkHeight">
          <div class="button-btn" v-if="readMore" @click="this.handleVisibleText">
            <p>Read More</p>
          </div>
          <div class="button-btn" v-if="hideText" @click="this.handleHideText">
            <p>Hide</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sd-items-list" v-if="getItems.list" :id="getIndex">
      <SolutionsMedia :items="getItems" />
      <div :style="compWidth">
        <div :style="compInnerWidth" class="sd-content">
          <h1 class="sd-item-title" v-html="$rt(getItems.title).replace(/[™®©]/g, '<sup>$&</sup>')"></h1>
          <div class="sd-items-para-points" v-for="(element, index) in getItems.list">
            <p v-if="element.paragraph">{{ $rt(element.paragraph) }}</p>

            <ul class="sd-ul" v-if="element.points">
              <li class="sd-li" v-for="(point, index) in element.points" v-html="$rt(point).replace(/[™®©]/g, '<sup>$&</sup>')"></li>
            </ul>
          </div>
        </div>
        <div style="padding-top: 1rem; display: flex; justify-content: center;" v-if="checkHeight">
          <div class="button-btn" v-if="readMore" @click="this.handleVisibleText">
            <p>Read More</p>
          </div>
          <div class="button-btn" v-if="hideText" @click="this.handleHideText">
            <p>Hide</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sd-items-list" v-if="getItems.subList" :id="getIndex">
      <SolutionsMedia :items="getItems" />
      <div :style="compWidth">
        <div :style="compInnerWidth" class="sd-content">
          <h1 class="sd-item-title">{{ $rt(getItems.title) }}</h1>
          <div v-for="subItem in getItems.subList.subValues">
            <ol v-if="$rt(subItem.subType) === 'numbered'" v-for="element in subItem.values">
              <li>{{ $rt(element.subHeader) }}</li>
              <ul>
                <li v-for="point in element.points">{{ $rt(point) }}</li>
              </ul>
            </ol>
            <div v-if="$rt(subItem.subType) === 'none'" v-for="element in subItem.values">
              <p>{{ $rt(element.subHeader) }}</p>
              <p v-for="item in element.paragraphs">{{ $rt(item) }}</p>
            </div>
          </div>
        </div>
        <div style="padding-top: 1rem; display: flex; justify-content: center;" v-if="checkHeight">
          <div class="button-btn" v-if="readMore" @click="this.handleVisibleText">
            <p>Read More</p>
          </div>
          <div class="button-btn" v-if="hideText" @click="this.handleHideText">
            <p>Hide</p>
          </div>
        </div>
      </div>
    </div>
    <BaseButton v-if="this.$props.items.isButton && this.$props.items.buttonPath != null"
      :label="this.$props.items.buttonText" :buttonStyle="DetailStyle" :url="this.$props.items.buttonPath" />
    <div v-if="getItems.isImageLinks" class="base-detail-image-link-box"
      style="padding-bottom: 2rem; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 10px; width: 100%;">
      <nuxt-link v-for="item in getItems.imageLinks" class="base-detail-link" :to="$rt(item.url)"
        @mouseover="this.handleVisibleText(item)" @mouseleave="this.handleHideText(item)">
        <p class="base-detail-link-img-text">{{ $rt(item.name) }}</p>
        <img class="base-detail-link-img" :src="$rt(item.resourceUri)" />
      </nuxt-link>
    </div>
  </div>
</template>
<script>
import BaseButton from '../base/BaseButton.vue';
import SolutionsMedia from './SolutionsMedia.vue';
import gsap from 'gsap';
export default {
  data() {
    return {
      height: '415px',
      innerHeight: '315px',
      overflow: 'hidden',
      id: null,
      solutionsData: null,
      isReadMore: true,
      isHideText: false,
      detailStyle: {
        color: '#0089D0',
        hover: '#212121',
        isHover: false,
      },
    };
  },
  components: {
    BaseButton,
    SolutionsMedia
  },
  mounted() {
    this.solutionsData = this.$props.items;
    this.id = this.$rt(this.solutionsData.index);
  },
  props: ['items'],
  computed: {
    getIndex () {
        
        return this.id;
    },
    checkHeight () {
      const height = gsap.getProperty('.sd-content', 'y', 'px')
      if(height != null) {
        console.log(height);
      }
      return true
    },
    compWidth () {
      return {
        'height': this.height,
        'overflow': this.overflow
      }
    },
    compInnerWidth() {
      return {
        'height': this.innerHeight,
        'overflow': this.overflow
      };
    },
    readMore() {
      return this.isReadMore;
    },
    hideText() {
      return this.isHideText;
    },
    DetailStyle() {
      return {
        color: '#0089D0',
        hover: '#212121',
      };
    },
    getItems() {
      return this.solutionsData;
    },
    detailTextContainer() {
      if (this.solutionsData.isRight) {
        return {
          float: 'right',
          width: '50%',
          padding: '0 0 1rem 1rem'
          // 'flex-direction': 'row',
        };
      } else {
        return {
          float: 'left',
          width: '50%',
          padding: '0 1rem 1rem 0'
        };
      }
    },
  },
  methods: {
    onReady() {
      this.$refs.youtube.playVideo();
    },
    handleVisibleText() {
      this.height = '100%';
      this.innerHeight = '100%'
      this.overflow = 'visible'
      this.isHideText = true;
      this.isReadMore = false;
    },
    handleHideText() {
      this.height = '415px';
      this.innerHeight = '315px',
      this.overflow = 'hidden'
      this.isHideText = false;
      this.isReadMore = true;
    },    
  }
};
</script>
<style scoped>
.solutions-detail-container {

  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  padding-left: 40px;
  padding-right: 40px;
}
.button-btn {
  background-color: rgb(0, 137, 208);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 180px;
  padding: 10px;
  line-height: 1.2;
}

.sd-item-title {
  margin-bottom: 20px;
    font-size: 35px;
      font-weight: 900;
      width: 100%;

}

/* .solution-detail-alt {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
  margin-top: 20px;
} */

.items-holder {

  flex: 1;
  width: 100%;
}

/* .solutions-detail-image-box {
  flex: 1;
  width: 100%;
} */

/* .sd-item-img {
  width: 100%;
} */

.sd-li {
  font-size: 15px;
}

.sd-ul {
  margin-top: 10px;
}

.sd-item-paragraph {
  margin-top: 15px;
}
.sd-content {
  height: 100%;
  /* box-sizing: border-box; */
}
.base-detail-link-img {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 5px;
}

.base-detail-link-img-text {
  position: relative;
  top: 20%;
  left: 10%;
  color: #FFF;
  font-weight: bold;
  font-style: italic;
  font-size: 17px;
  text-decoration: underline #fff;
  margin: 0;
  padding: 0;
}


.base-detail-link-img:hover {
  width: 100%;
  height: 100%;
}
.base-detail-link:hover {
  opacity: 0.8;
}
.sd-item-subheader {
  padding: 1rem 1rem 1rem 0rem;
  font-weight: 600;
}
@media only screen and (max-width: 750px) {

  .solutions-detail-container {
    flex-direction: column-reverse !important;
  }

  /* .solutions-detail-image-box {
    padding-left: 40px;
    padding-right: 40px;
  } */

}
/* @media only screen and (max-width: 500px) {

  .solutions-detail-image-box {
    padding-left: 0;
    padding-right: 0;
  }

} */
</style>