<template>
  <div
    class="detail-points-container"
    :id="$rt(items.title) + getItemIndex(items)"
  >
    <div class="detail-points-box" :style="detailTextContainer()">
      <h1 class="detail-points-header" id="detail-points-header">
        {{ $rt(items.title) }}
      </h1>

      <div v-if="!items.hasOwnProperty('listTitle')" v-for="(list, a) in items.list" >

        <p v-for="(paragraph, b) in list.paragraphs" class="detail-points-paragraph" id="detail-points-paragraphs" v-html="$rt(paragraph).replace(/[™®©]/g, '<sup>$&</sup>')"></p>
        <ul class="detail-points-bullet-items">
        <li
            class="detail-points-bullet-text"
            v-for="(point, c) in list.points"
        >
            {{ $rt(point) }}
          </li>
        </ul>
      </div>

      <div v-else>

        <p v-for="(paragraph, b) in items.paragraphs" class="detail-points-paragraph" id="detail-points-paragraphs" v-html="$rt(paragraph).replace(/[™®©]/g, '<sup>$&</sup>')"></p>
        <p class="detail-points-bullet-title">{{ $rt(items.listTitle) }}</p>
        <ul class="detail-points-bullet-items">
          <li
            class="detail-points-bullet-text"
            v-for="(item, index) in items.list"
            :key="index"
          >
            {{ $rt(item) }}
          </li>
        </ul>
      </div>

      <BaseButton
        v-if="Props.isButton"
        :label="Props.label"
        :buttonStyle="detailStyle"
        :url="Props.path"
      />
    </div>
    <div
      class="detail-points-media-box"
      :style="detailMediaContainer()"
      v-if="Props.isVideo"
    >
      <div v-for="(video, num) in Props.items.videoUri" :key="num">
        <iframe
          :src="$rt(video)"
          height="320"
          width="400"
          class="detail-video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        >
        </iframe>
        <div v-if="Props.items.alt != null">
          <p class="detail-points-alt-bar">{{ $rt(Props.items.alt[num]) }}</p>
        </div>
      </div>
    </div>
    <div class="detail-points-media-box" :style="detailMediaContainer()" v-else>
      <div v-for="(image, index) in Props.items.resourceUri" :key="index">
        <img :style="handleImage()" class="detail-points-image" :src="$rt(image)" />
        <div v-if="Props.items.alt != null" class="detail-points-alt">
          <p class="detail-points-alt-bar">{{ $rt(Props.items.alt[index]) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from './BaseButton.vue';
export default {
  components: {
    BaseButton,
  },
  data () {
    return {
      detailStyle: {
        color: '#0089D0',
        hover: '#212121',
      },
    };
  },
  props: [
    'items',
    'isButton',
    'isRight',
    'isVideo',
    'isList',
    'label',
    'width',
    'path',
  ],
  computed: {
    Props () {
      return this.$props;
    },
  },
  methods: {
    getItemIndex (item) {
      return (item.hasOwnProperty('index') && item.index !== null) ? this.$rt(item.index) : 0;
    },
    detailTextContainer () {
      if (this.$props.isRight) {
        return {
          'grid-column': '1/2',
          'grid-row': '1/2',
        };
      } else {
        return {
          'grid-column': '2/3',
          'grid-row': '1/2',
        };
      }
    },
    detailMediaContainer () {
      if (this.$props.isRight) {
        return {
          'grid-column': '2/3',
          'grid-row': '1/2',
        };
      } else {
        return {
          'grid-column': '1/2',
          'grid-row': '1/2',
        };
      }
    },
    handleImage () {
      if (this.$props.width != undefined) {
        return {
          width: this.$props.width,
        };
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.detail-points-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  padding: 3rem 0rem;
}

.detail-points-media-box {
  align-self: center;
}

.detail-points-box {
  display: flex;
  flex-direction: column;
}

.detail-points-bullet-items {
  padding-left: 1rem;
}

.detail-points-bullet-title {
  padding-bottom: 1rem;
  font-size: 15px;
}

.detail-points-bullet-text {
  padding-bottom: 10px;
  font-size: 15px;
}

#detail-points-paragraphs {
  white-space: break-spaces;
  line-height: 1.7em;
  margin-bottom: 1em;
}

#detail-points-header {
  align-self: center;
  margin-bottom: 15px;
  padding-bottom: 1rem;
  font-weight: 900;
}

.detail-points-alt {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.detail-points-alt-bar {
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
}

.detail-points-video {
  width: 100%;
}

.detail-points-image {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .detail-points-container {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .detail-points-image {
    width: 100%;
  }

  .detail-points-media-box,
  .detail-points-box {
    padding: 0 2rem;
  }

  .detail-points-paragraphs {
    font-size: 20px;
    padding: 0;
  }
}

.detail-points-paragraph {
  font-size: 15px;
}

.detail-points-header {
  font-size: 35px;
}
</style>
