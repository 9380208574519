<template>
  <component :is="getComponent" :items="getItems" :class="getClass" :id="getId" />
  <BaseLine v-if="!isLast" class="boxed" />
</template>

<script>
import BaseBanner from '~/components/base/BaseBanner.vue';
import BaseBlog from '~/components/base/BaseBlog.vue';
import BaseButton from '~/components/base/BaseButton.vue';
import BaseContent from '~/components/base/BaseContent.vue';
import BaseDetail from '~/components/base/BaseDetail.vue';
import BaseDetailList from '~/components/base/BaseDetailList.vue';
import BaseDetailPoints from '~/components/base/BaseDetailPoints.vue';
import BaseDetailQA from '~/components/base/BaseDetailQA.vue';
import BaseDropdown from '~/components/base/BaseDropdown.vue';
import BaseMedia from '~/components/base/BaseMedia.vue';
import BaseStory from '~/components/base/BaseStory.vue';
import BaseTestimonials from '~/components/base/BaseTestimonials.vue';
import BaseTextArea from '~/components/base/BaseTextArea.vue';
import BaseTextInput from '~/components/base/BaseTextInput.vue';
import BaseWebinars from '~/components/base/BaseWebinars.vue';
import BaseRecommendations from '~/components/base/BaseRecommendations.vue';
import BaseLine from '~/components/base/BaseLine.vue';
import ImageLinks from '~/components/ImageLinks.vue';
import ProductGuide from '~/components/ProductGuide.vue';
import SolutionsDetail from '~/components/solutions/SolutionsDetail.vue';
import SolutionsDetailText from './solutions/SolutionsDetailText.vue';
import SolutionsDetailWrap from './solutions/SolutionsDetailWrap.vue';
import ContactInfo from '~/components/ContactInfo.vue';
import BaseImageLink from '~/components/base/BaseImageLink.vue';
import ProductResources from './products/ProductResources.vue';

export default {
  components: {
    BaseLine,
    BaseBanner,
    BaseBlog,
    BaseButton,
    BaseContent,
    BaseDetail,
    BaseDetailList,
    BaseDetailPoints,
    BaseDetailQA,
    // BaseDropdown is currently not used
    BaseDropdown,
    BaseMedia,
    BaseRecommendations,
    ProductResources,
    BaseStory,
    BaseTestimonials,
    // BaseTextArea is currently not used
    BaseTextArea,
    // BaseTextInput is currently not used
    BaseTextInput,
    BaseWebinars,
    ImageLinks,
    ProductGuide,
    SolutionsDetail,
    SolutionsDetailWrap,
    SolutionsDetailText,
    ContactInfo,
    BaseImageLink,
  },
  props: ['item', 'isLast'],
  computed: {
    getComponent () {
      switch (this.$rt(this.$props.item.type)) {
        case 'banner':
          return 'BaseBanner';
        case 'detail':
          return 'BaseDetail';
        case 'tableOfContents':
          return 'BaseContent';
        case 'list':
          return 'BaseDetailList';
        case 'imageLinks':
          return 'ImageLinks';
        case 'testimonials':
          return 'BaseTestimonials';
        case 'webinars':
          return 'BaseWebinars';
        case 'blogs':
          return 'BaseBlog';
        case 'media':
          return 'BaseMedia';
        case 'defs':
          return 'BaseDetailQA';
        case 'pocketGuide':
          return 'ProductGuide';
        case 'detailPoints':
          return 'BaseDetailPoints';
        case 'solutionsDetail':
          return 'SolutionsDetail';
        case 'solutionsDetailWrap': 
          return 'SolutionsDetailWrap'
        case 'solutionsDetailText': 
          return 'SolutionsDetailText'
        case 'story':
          return 'BaseStory';
        case 'resources':
          return 'ProductResources';
        case 'products':
          return 'BaseRecommendations';
        case 'contactInfo':
          return 'ContactInfo';
        case 'BaseLine':
          return 'BaseLine';
        case 'baseImageLink':
          return 'BaseImageLink';
      }
    },
    getItems () {
      if(this.$rt(this.$props.item.type) === 'resources') {
        return this.$props.item.details
      } else {
        return this.$props.item
      }
    },
    getClass () {
      switch (this.$rt(this.$props.item.type)) {
        case 'banner':
        case 'products':
          return 'full';
        default:
          return 'boxed';
      }
    },
    getId () {
      if (this.$props.item.index != undefined) {
      return this.$rt(this.$props.item.index);
      } else return
    },
  },
  methods: {},
};
</script>

<style scoped>
.full {
  width: 100%;
}

.boxed {
  max-width: 1200px;
}
</style>
