<template>
  <div class="contact-button-container" v-if="getContactInfo">
    <div class="contact-button-box">
<!--        <BaseButton v-for="item in getContactInfo" :label="item.buttonText" :buttonStyle="dealerStyle" :url="item.buttonPath" />-->
      <BaseButton  :label="getContactInfo[0].buttonText" :buttonStyle="dealerStyle" :url="getContactInfo[0].buttonPath" />
      <BaseButton  :label="getContactInfo[1].buttonText" :buttonStyle="contactStyle" :url="getContactInfo[1].buttonPath" />
    </div>
  </div>
</template>

<script>
import BaseButton from './base/BaseButton.vue';

export default {
  components: { BaseButton },
  props: [
    'items'
  ],
  mounted () {
    this.contactInfo = this.$props.items.buttons;
  },
  data () {
    return {
      contactInfo: null,
      dealerStyle: {
        color: '#F48B41',
        hover: '#3B3B3B',
      },
      contactStyle: {
        color: '#3B3B3B',
        hover: '#F48B41',
      },
    };
  },
  computed: {
    getContactInfo () {
      return this.contactInfo;
    }
  }
};
</script>

<style scoped>
.contact-button-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 560px;
}
.contact-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #3b3b3b;
  height: 115px;
}
.content-find-dealer {
  margin-right: 5rem;
}
@media only screen and (max-width: 900px) {
  .contact-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-button-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 35px;
    height: 350px;

  }
  .content-find-dealer {
    padding-bottom: 1rem;
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {

  .contact-button-box {
    gap: 20px;
    height: auto;
  }

  .contact-button-container {
    height: auto;
  }
}

</style>
