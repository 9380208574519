<template>
  <div class="base-media-container">
    <div class="base-media-text-box">
      <h1 class="media-title" id="base-media-title">{{ $rt(Props.items.title) }}</h1>
      <p class="media-text" id="base-media-summary">{{ $rt(Props.items.paragraphs[0]) }}</p>
    </div>
    <div class="base-media-video-box">
      <iframe
        width="100%"
        height="500"
        class="base-media-video"
        src="https://www.youtube.com/embed/7j1lMs7fcIQ"
        title="Everything About Irrigation Pivots (Farmers are Geniuses) - Smarter Every Day 278"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: ['items'],
  computed: {
    Props () {
      return this.$props;
    },
  },
};
</script>
<style scoped>
.base-media-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EFECE0;

  padding: 50px 0;
}
.base-media-video {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  max-width: 1050px;
  max-height: 500px;
}
.base-media-video-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#base-media-title {
  margin-bottom: 15px;
}
#base-media-summary {
  text-align: center;
  margin-bottom: 1em;
  max-width: 1070px;
}
.base-media-text-box {
  text-align: center;
  padding: 0 1rem;
}

@media only screen and (max-width: 1200px) {
  .base-media-container {
    width: 100%;
  }
  .base-media-video {
    width: 90%;
    height: 335px;
  }
}

.media-title {
  font-weight: 900;
  font-size: 30px;
}

.media-text {
  font-size: 15px;
}
</style>
