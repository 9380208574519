<template>
  <div class="base-image-link" v-if="getItems != null">
    <div class="base-imgae-header-box" :id="$rt(getItems.index)">
      <h1 class="base-image-header">{{ $rt(getItems.title) }}</h1>
    </div>
    <nuxt-link style="width: 100%;" :to="$rt(getItems.link)">
      <div class="bil-image-box" :style="{
      height: '500px',
      width: '100%',
      backgroundImage: 'url(' + $rt(getItems.resourceUri[0]) + ')',
      backgroundPosition: 'center',
      backgroundSize: 'cover'

    }">
        <!-- <div class="base-link-holder">
        <nuxt-link class="base-link" :to="getItems.link">{{
          $rt(getItems.link)
        }}</nuxt-link>
      </div> -->
        <!-- <img :src="getItems.resourceUri" /> -->
      </div>
    </nuxt-link>
    <nuxt-link :to="$rt(getItems.link)" class="base-link-holder">
      <h1 class="base-link">{{ $rt(getItems.linkName) }}</h1>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  mounted () {
    this.linkData = this.$props.items;
  },
  data () {
    return {
      linkData: null,
    };
  },
  props: ['items'],
  computed: {
    getItems () {
      return this.linkData;
    },
  },
};
</script>

<style scoped>
.base-image-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bil-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.base-link-holder {
  width: 100%;
  font-size: 35px;
  color: #0089D0;
  text-decoration: underline #0089D0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  /* display: none; */
  color: black;
  /* opacity: 0.6;
  transition: 2s ease-in; */
}
/* .bil-image-box:hover {
  filter: blur(4px);
} */
 .bil-image-box:hover div.base-link-holder {
  color: #FFF;
  opacity: 1;
  text-decoration: underline #fff;
  display: flex;
  justify-content: center;
}

.base-link {
  font-size: 25px;
  font-weight: 650;
  color: #0089D0;
}
.base-image-header {
  width: 100%;
  font-size: 35px;
  font-weight: 900;
  padding: 0;
  max-width: 1200px;
  margin: 0 0 40px;
}
/* .base-link-holder {
  width: 100%;
  font-size: 22px;
  color: black;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
} */
.base-imgae-header-box{
  display: flex;
  align-items: center;
}
</style>
