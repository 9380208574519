<template>
  <div class="image-links-container">
    <div class="image-links-box" :id="$rt(Props.items.index)">
      <img class="image-links-img" :src="$rt(Props.items.resourceUri)" />
      <div class="image-links-text-layout">
        <div
          v-for="(item, index) in Props.items.items"
          :key="index"
          class="plus-icons"
          :id="'plus' + index"
        >
          <nuxt-link
            class="image-links-button-text"
            :to="$rt(item.videoUri)"
          >
            <div
              class="image-links-icon-circle"
              :id="'icon' + index"
              @mouseover="this.handleHoverIcon(index)"
              @mouseleave="this.handleLeaveIcon(index)"
            >
              +
            </div>
            <p class="image-links-text">{{ $rt(item.title) }}</p>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap';
export default {
  props: ['items'],
  computed: {
    Props () {
      return this.$props;
    },
  },
  methods: {
    handleBackgroundImage () {
      return {
        'background-image': 'url(' + this.$props.items.resourceUri + ')',
      };
    },
    handleVideoLink (item) {
      window.open(item.videoUri);
    },
    handleHoverIcon (index) {
      const id = '#icon' + index;
      gsap.to(id, {
        backgroundColor: '#1CA0C7',
      });
    },
    handleLeaveIcon (index) {
      const id = '#icon' + index;
      gsap.to(id, {
        backgroundColor: '#03B3F6',
      });
    },
  },
};
</script>
<style scoped>
.image-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-links-text-layout {
  margin-top: 50px;
  align-self: center;
  justify-self: center;
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
}

.image-links-box {
  color: #ffffff;
  display: grid;
  grid-template-columns: 1260px;
}

.image-links-img {
  align-self: center;
  justify-self: center;
  grid-row: 1/2;
  grid-column: 1/2;
  width: 90%;
}
.image-links-plus {
  font-size: 35px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#plus0 {
  grid-row: 3/4;
  grid-column: 1/2;
  justify-self: center;
}

#plus1 {
  grid-row: 4/5;
  grid-column: 2/3;
  justify-self: flex-start;
  align-self: flex-end;
}

#plus2 {
  grid-row: 5/6;
  grid-column: 3/4;
  justify-self: flex-start;
}

#plus3 {
  grid-row: 5/7;
  grid-column: 4/5;
  align-self: center;
}

#plus4 {
  grid-row: 5/7;
  grid-column: 5/6;
  align-self: center;
}

.image-links-icon-circle {
  background-color: #03b3f6;
  width: 45px;
  height: 45px;
  font-size: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-links-icon {
  color: #ffffff;
  font-weight: bolder;
}

.image-links-text {
  font-weight: 600;
  font-size: 17px;
}

.image-links-button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.image-links-img {
  grid-column: 1/8;
  grid-row: 1/9;
}

@media only screen and (max-width: 1200px) {
  .image-links-container {
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .image-links-text-layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
    padding: 0;
    margin: 0;
  }
  .image-links-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  #plus0 {
    grid-row: 2/3;
    grid-column: 1/2;
  }

  #plus1 {
    grid-row: 2/3;
    grid-column: 2/3;
    align-self: center;
  }

  #plus2 {
    grid-row: 2/3;
    grid-column: 3/4;
  }

  #plus3 {
    grid-row: 2/3;
    grid-column: 4/5;
  }

  #plus4 {
    grid-row: 2/3;
    grid-column: 5/6;
  }

  .image-links-img {
    width: 100%;
    object-fit: cover;
    align-self: center;
    justify-self: center;
  }

  .image-links-button-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .image-links-icon-circle {
    height: 45px;
    width: 45px;
  }

  .plus-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .image-links-text {
    color: black;
    font-size: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .image-links-container {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
  }
  .image-links-text-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #dcdcdc;
  }
  .image-links-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
  }
  .image-links-button-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .image-links-img {
    width: 100%;
  }
  .plus-icons {
    padding: 10;
  }
  .image-links-text {
    font-size: 15px;
    text-decoration: underline;
  }
  .image-links-icon-circle {
    display: none;
  }
}
</style>
