<template>
  <div class="product-guide-container" :style="detailContainer()" :id="$rt(Props.items.title) + $rt(Props.items.index)">
    <div class="product-guide-box">
      <h1 class="product-guide-title" id="product-guide-title">{{ $rt(Props.items.title) }}</h1>
      <p class="product-guide-text" id="product-guide-paragraphs">{{ $rt(Props.items.paragraph) }}</p>
      <BaseButton v-if="Props.items.isButton" :label="$rt(Props.items.buttonText)" :buttonStyle="detailStyle"
        :url="$rt(Props.items.buttonPath)" />
    </div>
    <div>
      <div class="product-guide-image-box" v-for="(image, index) in Props.items.resourceUri" :key="index">
        <img :style="handleImage()" class="product-guide-image" :src="$rt(image)" />
        <div v-if="Props.items.alt != null" class="product-guide-alt">
          <p class="product-guide-alt-bar">{{ $rt(Props.items.alt[index]) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from './base/BaseButton.vue';
export default {
  components: {
    BaseButton,
  },
  data () {
    return {
      detailStyle: {
        color: '#0089D0',
        hover: '#212121'
      }
    };
  },
  props: [
    'items',
    'width'
  ],
  computed: {
    Props () {
      return this.$props;
    }
  },
  methods: {
    detailContainer () {
      if (this.$props.items.isRight) {
        return {
          'display': 'flex',
          'flex-direction': 'row',
        };
      } else {
        return {
          'display': 'flex',
          'flex-direction': 'row-reverse',
        };
      }
    },
    handleImage () {
      if (this.$props.width != undefined) {
        return {
          'width': this.$props.width
        };
      } else {
        return;
      }
    }
  }

};
</script>
<style scoped>
.product-guide-container {
  max-width: 1200px;
  background-color: #EFECE0;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.product-guide-box {
  display: flex;
  flex-direction: column;
  padding: 40px;


}
#product-guide-paragraphs {
  white-space: break-spaces;

}
#product-guide-title {
  align-self: center;
  margin-bottom: 15px;
}
.product-guide-alt {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}
.product-guide-alt-bar {
  font-size: 18px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #D3CDC2;
}
.product-guide-image-box {
  background-color: #F48B41;
  padding: 15px;
  height: 450px;
}
.product-guide-image {
  height: 100%;
}
.product-guide-title {
  margin-top: 40px;
  font-size: 35px;
  font-weight: 900;
}

.product-guide-text {
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  .product-guide-box {
    justify-self: center;
    align-self: center;
  }
  .product-guide-image-box {
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .product-guide-container {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .product-guide-image {
    background-color: #F48B41;
    padding: 1rem;
    width: 80%;
  }
}

@media only screen and (max-width: 1200px) {
  .product-guide-title {
    margin-top: 10px;
  }

  .product-guide-box {
    padding: 20px;
  }
}


</style>